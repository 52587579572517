import React, { FC } from 'react';
import { Layout } from 'antd';
import PatientForm from '../../components/PatientForm/PatientForm';
import { IPatient } from '../../models/pages/IPatient';
import './../../scss/Patient.scss';
import { inject, observer } from 'mobx-react';

/**
 * @memberof patientmanagement
 * @component Patient
 * @type {React.FC<IPatient>}
 * @return {React.ReactElement}
 */
const Patient: FC<IPatient> = ({ patientCareStore }: IPatient): JSX.Element => {
  return (
    <Layout className="content-wrapper content-scroll-wrapper">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          minHeight: '100%',
          height: '100%'
        }}
      >
        <PatientForm patientCareStore={patientCareStore} />
      </div>
    </Layout>
  );
};

export default inject('patientCareStore')(observer(Patient));
