import React, { FC } from 'react';
import { MenuProps, Typography } from 'antd';
import { Menu } from 'antd';
import { getMenuItems } from '../../config/menu';
import { useTranslation } from 'react-i18next';
import { ISidebarMenu } from '../../models/components/ISidebarMenu';

const { Title } = Typography;

/**
 * ---
 * SidebarMenu
 * ---
 * @function patientmanagement#components/SidebarMenu
 * @type {React.FC<ISidebarMenu>}
 * @return {React.ReactElement}
 */
const SidebarMenu: FC<ISidebarMenu> = (): JSX.Element => {
  const [current, setCurrent] = React.useState('1');
  const { t } = useTranslation();

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const menuItems = getMenuItems(t);

  return (
    <>
      <Title level={5} className="dtb-case-sidebar-menu-title">
        {t('pm-nav:titlePatientCare')}
      </Title>
      <Menu
        inlineIndent={12}
        onClick={onClick}
        style={{
          width: 200,
          height: 'auto',
          display: 'block'
        }}
        defaultOpenKeys={['sub1']}
        selectedKeys={['cases']}
        mode="inline"
        items={menuItems}
        className="dtb-header-menu"
      />
    </>
  );
};

export default SidebarMenu;
