import React, { FC } from 'react';
import { Card, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ICaseTreatment } from '../../models/components/ICaseTreatment';

const { Text } = Typography;

/**
 * ---
 * CaseTreatment
 * ---
 * @function patientmanagement#components/CaseTreatment
 * @type {React.FC<ICaseTreatment>}
 * @return {React.ReactElement}
 */
const CaseTreatment: FC<ICaseTreatment> = ({
  showSkeleton,
  patientCareStore,
  size = 'default'
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        title={t('patient:treatment')}
        size={size}
        className="dtb-case-card-treatment"
      >
        {showSkeleton ? (
          <>
            <br />
            <Skeleton
              title={false}
              paragraph={{ rows: 3 }}
              style={{ margin: 0, padding: 0 }}
            />
          </>
        ) : (
          <div style={{ marginTop: 10 }}>
            <Text>
              {t('patient:treatmentId')}:{' '}
              {patientCareStore?.selectedCase?.caseToken}
            </Text>
            <br />
            <Text>
              {t('patient:treatment')}: {patientCareStore?.selectedCase?.title}
            </Text>
            <br />
            <Text>
              {t('patient:description')}:{' '}
              {patientCareStore?.selectedCase?.description === null
                ? '-'
                : patientCareStore?.selectedCase?.description}
            </Text>
          </div>
        )}
      </Card>
    </>
  );
};

export default CaseTreatment;
